import React, { useEffect, useRef, useContext, useState } from "react";
// import { Button, AppBar, Toolbar, Typography, IconButton } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import Header from "./Header";
// import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import ButtonGroup from "@mui/material/ButtonGroup";
import { Box, Typography, Popover } from "@mui/material";
import MenuCanvas from "./MenuCanvas";
import Stack from "@mui/material/Stack";
// import Container from "@mui/material/Container";
import CanvasHeader from "./CanvasHeader";
import CanvasMenuItemImage from "./CanvasMenuItemImage";
import CanvasMenuItemTypography from "./CanvasMenuItemTypography";
import CanvasMenuGridList from "./CanvasMenuGridList";

import { useSelector, useDispatch } from "react-redux";
// import { updateCanvasMenu } from "./redux-slices/CanvasMenuSlice";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
// import ImageToCanvas from "./ImageToCanvas";
import CanvasArea from "./CanvasArea";

import {
  // oilPainting,
  // sketchStyle,
  // cartoonizerEffect,
  // watercolorEffect,
  // enhanceImage,
  // resizeImage,
  // removeBackgroundAI,
  // styleTransfer,
  // lineArt,
  // removeBackgroundAI2,
  cartoonizerEffect2,
  // styleTransfer2,
  convertToSketch2,
  convertToSketchOpencv,
  imageToVectorBlackAndWhite,
  backgroundRemoverREMBG,
} from "./image-processing-tools";
import { useMediaQuery } from "@mui/material";
import { unsetGridMenuClicked } from "./redux-slices/GridMenuClickedSlice";
import TemplateList from "./TemplateList";
import FloatingToolbar from "./FloatingToolbar";
import { Helmet } from "react-helmet";
import CanvasAreaForDesign from "./CanvasAreaV2";
import MenuCanvasV2 from "./MenuCanvasV2";
import CanvasAreaV2 from "./CanvasAreaV2";
import LayersComponent from "./DesignCanvasComponents/LayersComponent";
import TextSettings from "./DesignCanvasComponents/TextSettings";
import { CanvasContext } from "./CanvasContext";
import { fabric } from "fabric";
import TextSettingsPopover from "./DesignCanvasComponents/TextSettingsPopover";

// import { convertToGrayscale } from "./image-manipulations-tools";
// import { unsetGridMenuClicked } from "./redux-slices/GridMenuClickedSlice";

const artTempObject = [
  // { style: "Cartoonizer", handleFunction: cartoonizerEffect },
  { style: "Cartoonizer 2", handleFunction: cartoonizerEffect2 },
  // { style: "Oil Paint", handleFunction: oilPainting },
  // { style: "Watercolor", handleFunction: watercolorEffect },
  { style: "Sketch", handleFunction: convertToSketchOpencv },
  { style: "Sketch 2", handleFunction: convertToSketch2 },
  // { style: "Style Transfer", handleFunction: styleTransfer },
  // { style: "Style Transfer2", handleFunction: styleTransfer2 },
  // { style: "Line Art", handleFunction: lineArt },
  {
    style: "Vectorize Black & White",
    handleFunction: imageToVectorBlackAndWhite,
  },
  {
    style: "Vectorize Black & White 2",
    handleFunction: "",
  },
  {
    style: "Background Remover",
    handleFunction: backgroundRemoverREMBG,
  },
];
const editObject = [
  { style: "Image Enhancement", handleFunction: "" },
  { style: "Crop", handleFunction: "" },
  { style: "Image Resize", handleFunction: "" },
  { style: "Rotate", handleFunction: "" },
  // { style: "Background Remover", handleFunction: removeBackgroundAI },
  // { style: "Background Remover2", handleFunction: removeBackgroundAI2 },
  // { style: "Hue & Saturation", handleFunction: "" },
  // { style: "Exposure", handleFunction: "" },
  { style: "Grayscale", handleFunction: "" },
];

const templates = [
  { style: "Shirt Templates", handleFunction: "" },
  { style: "Shirt Templates 2", handleFunction: "" },
];
const CanvasV2 = () => {
  const { fabricCanvasGlobal, selectedObject, setSelectedObject } =
    useContext(CanvasContext);
  // const [selectedObject, setSelectedObject] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElText, setAnchorElText] = React.useState(null);

  const dispatch = useDispatch();
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // const dispatch = useDispatch();
  const selectedMenu = useSelector((state) => state.selectedMenu.value);
  // const gridMenuClicked = useSelector(
  //   (state) => state.gridMenuClicked.gridMenuClicked
  // ); // Access the image URL from the Redux store

  let canvasMenu;
  if (selectedMenu === "image") canvasMenu = <CanvasMenuItemImage />;
  else if (selectedMenu === "text") canvasMenu = <CanvasMenuItemTypography />;
  else if (selectedMenu === "art")
    canvasMenu = <CanvasMenuGridList menu={artTempObject} />;
  else if (selectedMenu === "edit")
    canvasMenu = <CanvasMenuGridList menu={editObject} />;
  else if (selectedMenu === "templates")
    canvasMenu = <TemplateList menu={templates} />;
  const canvasRef = useRef(null);
  const gridItemRef = useRef(null);

  useEffect(() => {
    const resizeCanvas = () => {
      const gridItem = gridItemRef.current;
      const canvas = canvasRef.current;
      if (gridItem && canvas) {
        canvas.width = gridItem.offsetWidth;
        canvas.height = gridItem.offsetHeight;
      }
    };

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas(); // Initial resize

    return () => window.removeEventListener("resize", resizeCanvas);
  }, []);

  //menu style panel for mobile dimensions
  let menuStyleElement;
  if (selectedMenu === "image") {
    menuStyleElement = (
      <Box
        sx={{
          position: "absolute",
          top: "-25vh",
          left: 0,
          right: 0,
          borderStyle: "outset",
          backgroundColor: "#777777",
          // opacity: "90%",
        }}
      >
        {canvasMenu}
      </Box>
    );
  } else if (selectedMenu === "edit") {
    menuStyleElement = (
      <Box
        sx={{
          position: "absolute",
          top: "-12vh",
          left: 0,
          right: 0,
          borderStyle: "outset",
          backgroundColor: "#777777",
          // opacity: "90%",
        }}
      >
        {canvasMenu}
      </Box>
    );
  } else if (selectedMenu === "art") {
    menuStyleElement = (
      <Box
        sx={{
          position: "absolute",
          top: "-10vh",
          left: 0,
          right: 0,
          borderStyle: "outset",
          backgroundColor: "#777777",
          // opacity: "90%",
        }}
      >
        {canvasMenu}
      </Box>
    );
  } else if (selectedMenu === "text") {
    menuStyleElement = (
      <Box
        sx={{
          position: "absolute",
          top: "-55vh",
          left: 0,
          right: 0,
          borderStyle: "outset",
          backgroundColor: "#777777",
          // opacity: "90%",
        }}
      >
        {canvasMenu}
      </Box>
    );
  } else if (selectedMenu === "templates") {
    menuStyleElement = (
      <Box
        sx={{
          position: "absolute",
          top: "-10vh",
          left: 0,
          right: 0,
          borderStyle: "outset",
          backgroundColor: "#777777",
          // opacity: "90%",
        }}
      >
        {canvasMenu}
      </Box>
    );
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Add event listeners for object selection
  useEffect(() => {
    if (!fabricCanvasGlobal) return;

    const handleObjectSelected = (e) => {
      // Check if there is a selected object
      if (e.selected && e.selected.length > 0) {
        const activeObject = e.selected[0]; // Access the first selected object
        if (activeObject.type === "i-text") {
          activeObject.shading = {
            activeShading: "",
            offsetX: 0,
            offesetY: 0,
            blur: 0,
            color: "",
            isOpenTextShading: false,
          };
          console.log(
            "BREAKPOINT activeObject= " + JSON.stringify(activeObject, null, 2)
          );
          console.log(
            "BREAKPOINT activeObject.customField1= " + activeObject.customField1
          );
          setSelectedObject(activeObject); // Update the selected object state

          const { left, top } = activeObject.getBoundingRect();
          setAnchorElText({
            // top: 0,
            // right: 0,
          });
        } else {
          setSelectedObject(null); // Not a text object
          setAnchorEl(null);
        }
      }
    };

    const handleObjectDeselected = () => {
      setSelectedObject(null);
      setAnchorElText(null);
    };

    fabricCanvasGlobal.on("selection:created", handleObjectSelected);
    fabricCanvasGlobal.on("selection:updated", handleObjectSelected);
    fabricCanvasGlobal.on("selection:cleared", handleObjectDeselected);

    return () => {
      fabricCanvasGlobal.off("selection:created", handleObjectSelected);
      fabricCanvasGlobal.off("selection:updated", handleObjectSelected);
      fabricCanvasGlobal.off("selection:cleared", handleObjectDeselected);
    };
  }, [fabricCanvasGlobal]);

  // // Update Fabric.js object when text settings change
  // const handleTextSettingsChange = (settings) => {
  //   if (!selectedObject) return;
  //   const {
  //     fontSize,
  //     fontFamily,
  //     fill,
  //     textAlign,
  //     stroke,
  //     strokeWidth,
  //     opacity,
  //   } = settings;

  //   selectedObject.set({
  //     fontSize,
  //     fontFamily,
  //     fill,
  //     textAlign,
  //     stroke,
  //     strokeWidth,
  //     opacity: opacity / 100, // Convert percentage to decimal
  //   });

  //   fabricCanvasGlobal.requestRenderAll();
  // };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Helmet>
          <title>Canvas Design Tool | Okay Artist</title>
          <meta
            name="description"
            content="Create and edit your custom designs with Okay Artist's Canvas Tool. Add images, text, apply effects, and more."
          />
          <meta
            name="keywords"
            content="canvas design tool, custom designs, image editor, photo effects, okay artist, design templates, vectorize images, background remover"
          />
          <meta
            property="og:title"
            content="Canvas Design Tool | Okay Artist"
          />
          <meta
            property="og:description"
            content="Use the Okay Artist Canvas Design Tool to create and customize your artwork with advanced image editing options like cartoonizer, sketch effects, and vectorization."
          />
          <meta property="og:url" content="https://www.okayartist.com/canvas" />
          <meta
            property="og:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Canvas Design Tool | Okay Artist"
          />
          <meta
            name="twitter:description"
            content="Design and customize your artwork with Okay Artist's advanced Canvas Tool, offering a variety of effects and templates."
          />
          <meta
            name="twitter:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
        </Helmet>
        {/* <Header /> */}

        {/* <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6">Image Processing Canvas</Typography>
          </Toolbar>
        </AppBar> */}
        <CanvasHeader />

        <Box
          //   variant="outlined"
          //   square={false}
          // sx={{ backgroundColor: "#535353" }}
          sx={{
            // minHeight: "100vh",
            backgroundColor: "#535353",
            position: "relative",
          }}
        >
          {!matchesXS && (
            <Box
              container
              // sx={{ position: "relative" }}
              // alignItems="stretch"
              // height={"80vh"}

              // margin={"0"}
            >
              <Box
                //   backgroundColor={"#535353"}
                sx={{ position: "sticky", top: "20%", ml: "1%" }}
              >
                <Stack
                  alignItems={{ xs: "flex-start", md: "flex-start" }}
                  justifyContent={{ xs: "flex-start", md: "flex-start" }}
                  direction={{ xs: "column", sm: "row" }}
                >
                  <MenuCanvasV2
                    handleClick={handleClick}
                    // onClick={() => {
                    //   dispatch(unsetGridMenuClicked());
                    // }}
                  />

                  {/* <Button
                    aria-describedby={id}
                    variant="contained"
                    onClick={handleClick}
                  >
                    Open Popover
                  </Button> */}
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        //   width: "80%",
                        borderStyle: "outset",
                        backgroundColor: "#535353",
                        // overflow:"scroll"
                      }}
                    >
                      {/* <CanvasMenuItemImage /> */}
                      {canvasMenu}
                    </Box>
                  </Popover>
                </Stack>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // height: "100vh",
                  backgroundColor: "#535353",
                  // position: "relative",
                }}
              >
                {/* <CanvasArea
                  // ref={canvasRef}
                  // id="imageCanvas"
                  ></CanvasArea> */}
                <CanvasAreaV2></CanvasAreaV2>
              </Box>
            </Box>
          )}
          <Box sx={{ position: "sticky", bottom: "1%", ml: "1%" }}>
            <LayersComponent></LayersComponent>
          </Box>
          <Box sx={{ position: "absolute", top: "25%", right: 0 }}>
            {/* Text Settings Popover */}
            <TextSettingsPopover
              selectedObject={selectedObject}
              // handleTextSettingsChange={handleTextSettingsChange}
              anchorElText={anchorElText}
              setAnchorEl={setAnchorElText}
              setSelectedObject={setSelectedObject}
              setAnchorElText={setAnchorElText}
              fabricCanvasGlobal={fabricCanvasGlobal}
            />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
export default CanvasV2;
