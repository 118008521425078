import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setText } from "./redux-slices/TextSlice";
import { fabric } from "fabric";
import { CanvasContext } from "./CanvasContext";
import { useMediaQuery } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {
  setActiveTemplate,
  setImgTemplateURL,
} from "./redux-slices/ImageSlice";
import zIndex from "@mui/material/styles/zIndex";
import FloatingToolbar from "./FloatingToolbar";
import Box from "@mui/material/Box";
import ConvertImageToGrayscale from "./OpencvJS/ConvertImageToGrayscale";
import MaskImages from "./OpencvJS/MaskImages";
import { setImageURL } from "./redux-slices/ImageSlice";
import { adjustCanvasZooming } from "./Custom Hooks/CommonEffectsDesign";

const CanvasAreaV2 = () => {
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const {
    canvasRef,
    canvasRefTemplate,
    setCanvasAspectRatio,
    setImgAspectRatio,
    setScaleGlobal,
    imgWidth,
    imgHeight,
    open,
    setOpen,
    artBoardSettings,
    setArtBoardSettings,
    fabricCanvasGlobal,
    setFabricCanvasGlobal,
  } = useContext(CanvasContext);
  // const canvasRef = useRef(null);
  const imageURL = useSelector((state) => state.image.imageURL); // Access the image URL from the Redux store
  const activeTemplate = useSelector((state) => state.image.activeTemplate); // Access the image URL from the Redux store
  const textOption = useSelector((state) => state.textState.text);
  const dispatch = useDispatch();

  const [isCanvasInitialized, setIsCanvasInitialized] = React.useState(false);

  useEffect(() => {
    if (true) {
      // Initialize Fabric.js canvas
      const fabricCanvas = new fabric.Canvas(canvasRef.current, {
        width: artBoardSettings.width,
        height: artBoardSettings.height,
        // width: imgWidth,
        // height: imgHeight,
        backgroundColor: "white",

        preserveObjectStacking: true,
        interactive: true, // Ensure interactivity is enabled
      });
      setIsCanvasInitialized(true);

      canvasRef.current.fabric = fabricCanvas; // Store the Fabric.js canvas instance
      setFabricCanvasGlobal(fabricCanvas);
      adjustCanvasZooming(fabricCanvas);
      // setFabricCanvasGlobal(fabricCanvas);

      return () => {
        fabricCanvas.dispose();
        dispatch(setImgTemplateURL(""));
        // window.removeEventListener("resize", resizeCanvas);
        setCanvasAspectRatio(1);
        setImgAspectRatio(1);
        // dispatch(setImageURL(""));
        // setIsCanvasInitialized(false);
      };
    }
  }, [isCanvasInitialized]);

  //addition of text feature
  useEffect(() => {
    if (textOption) {
      const fabricCanvas = canvasRef.current.fabric; // Ensure this references the Fabric.js canvas
      if (!fabricCanvas) return;

      const newText = new fabric.IText("Edit Text", {
        left: 100, // Position from the left on the canvas
        top: 100, // Position from the top on the canvas
        fontFamily: "Arial",
        fill: "white",
        fontSize: 20,
      });

      fabricCanvas.add(newText);
      fabricCanvas.bringToFront(newText);
      fabricCanvas.renderAll(); // Render to display the changes
      dispatch(setText());
    }
  }, [textOption]);

  return (
    <div
    // id="canvas"
    // style={{ position: "relative" }}
    // onClick={textOption ? addText : ""}
    >
      <Box>
        <canvas
          id="canvas"
          ref={canvasRef}
          style={{
            // maxWidth: "100%",
            // maxHeight: "85vh",
            // border: "1px solid black",
            // backgroundColor: "white",
            display: "block",
            // marginLeft: "auto",
            // marginRight: "auto",
            // zIndex: "0",
          }}
        ></canvas>
      </Box>
      {/* <MaskImages /> */}

      {/* <TypographyCanvas /> */}
    </div>
  );
};
export default CanvasAreaV2;
