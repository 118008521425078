import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Slider,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatUnderlined,
} from "@mui/icons-material";

import {
  FormatAlignJustify,
  VerticalAlignBottom,
  VerticalAlignCenter,
  VerticalAlignTop,
  Lock,
  LockOpen,
} from "@mui/icons-material";

import AlignVerticalTopIcon from "@mui/icons-material/AlignVerticalTop";
import AlignVerticalCenterIcon from "@mui/icons-material/AlignVerticalCenter";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import AlignHorizontalRightIcon from "@mui/icons-material/AlignHorizontalRight";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import { CanvasContext } from "../CanvasContext";
import TextProperties from "./TextProperties";
import TextTransformationComponent from "./TextTransformationComponent";
import TextShadingComponent from "./TextShadingComponent";
import TextBorderComponent from "./TextBorderComponent";

const TextSettings = ({
  // currentSettings,
  onChange,
  selectedObject,
  fabricCanvasGlobal,
}) => {
  const { artBoardSettings } = useContext(CanvasContext);
  const [textSettings, setTextSettings] = React.useState({
    fontSize: selectedObject.fontSize || 16,
    fontFamily: selectedObject.fontFamily || "Arial",
    fontWeight: selectedObject.fontWeight || "normal",
    fill: selectedObject.fill || "#000000",
    textAlign: selectedObject.textAlign || "left",
    stroke: selectedObject.stroke || "",
    strokeWidth: selectedObject.strokeWidth || 0,
    opacity: (selectedObject.opacity || 1) * 100,
    width: selectedObject.getScaledWidth() || 0,
    height: selectedObject.getScaledHeight() || 0,
    fontStyle: selectedObject.fontStyle || "normal",
    underline: selectedObject.underline || false,
    charSpacing: selectedObject.charSpacing || 0,
    lineHeight: selectedObject.lineHeight || 1,
    // fontStyle: [
    //   ...(selectedObject.fontWeight === "bold" ? ["bold"] : []),
    //   ...(selectedObject.fontStyle === "italic" ? ["italic"] : []),
    //   ...(selectedObject.underline ? ["underline"] : []),
    // ],
  });
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [isLocked, setIsLocked] = useState(true);
  // Local state for input fields
  const [dimensions, setDimensions] = useState({
    width: selectedObject?.getScaledWidth() || 0,
    height: selectedObject?.getScaledHeight() || 0,
  });
  const [isChildListenerActive, setIsChildListenerActive] = useState(false);

  // console.log(
  //   "BREAKPOINT selectedObject= " + JSON.stringify(selectedObject, null, 2)
  // );
  React.useEffect(() => {
    if (selectedObject) {
      setDimensions({
        width: selectedObject.getScaledWidth(),
        height: selectedObject.getScaledHeight(),
      });
      const strokeWidth = selectedObject.strokeWidth || 0;

      setWidth(Math.round(selectedObject.getScaledWidth() * 100) / 100);
      setHeight(Math.round(selectedObject.getScaledHeight() * 100) / 100);
      console.log(
        "BREAKPOINT selectedObject.width= " + selectedObject.getScaledWidth()
      );
      console.log(
        "BREAKPOINT selectedObject.height= " + selectedObject.getScaledHeight()
      );
      console.log(
        "BREAKPOINT selectedObject.strokeWidth= " + selectedObject.strokeWidth
      );
    }
  }, [selectedObject]);

  // Update Fabric.js object when text settings change
  const handleTextSettingsChange = (settings) => {
    if (!selectedObject) return;
    const {
      fontSize,
      fontFamily,
      fontWeight,
      fontStyle,
      fill,
      textAlign,
      stroke,
      strokeWidth,
      opacity,
      width,
      height,
      underline,
      charSpacing,
      lineHeight,
    } = settings;

    selectedObject.set({
      fontSize,
      fontFamily,
      fontWeight,
      fontStyle,
      fill,
      textAlign,
      stroke,
      strokeWidth,
      opacity: opacity / 100, // Convert percentage to decimal
      width,
      height,
      underline,
      charSpacing,
      lineHeight,
    });

    fabricCanvasGlobal.requestRenderAll();
  };
  const handleChange = (key, value) => {
    console.log("BREAKPOINT ----key= " + key + "---value=" + value);
    // Update local state
    setTextSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));

    // Apply changes to the Fabric.js object if necessary
    if (key === "fontStyle" && selectedObject) {
      selectedObject.set({
        fontWeight: value.includes("bold") ? "bold" : "normal",
        fontStyle: value.includes("italic") ? "italic" : "normal",
        underline: value.includes("underline"),
      });
    }
  };

  const handleInputChange = (key, value) => {
    // Update input state directly without applying it immediately to the object
    if (key === "width") {
      handleApplyDimension(key, value);
      setWidth(value);
    } else if (key === "height") {
      handleApplyDimension(key, value);
      setHeight(value);
    }
  };
  const handleApplyDimension = (key, value) => {
    if (!selectedObject || !fabricCanvasGlobal) return;

    // Get the current canvas scaling factor
    const scaleFactor = fabricCanvasGlobal.getZoom() || 1;

    // Get the current bounding rectangle dimensions
    const boundingRect = selectedObject.getBoundingRect();
    const currentWidth = boundingRect.width / scaleFactor;
    const currentHeight = boundingRect.height / scaleFactor;

    // Define the new dimensions based on the key
    let newWidth = currentWidth;
    let newHeight = currentHeight;

    if (key === "width") {
      newWidth = value;
      if (isLocked) {
        // Adjust height proportionally if locked
        newHeight = newWidth / (currentWidth / currentHeight);
      }
    } else if (key === "height") {
      newHeight = value;
      if (isLocked) {
        // Adjust width proportionally if locked
        newWidth = newHeight * (currentWidth / currentHeight);
      }
    }

    // Calculate scale factors for both width and height
    const widthScaleFactor = newWidth / currentWidth;
    const heightScaleFactor = newHeight / currentHeight;

    // Update the object's scale
    selectedObject.set({
      scaleX: selectedObject.scaleX * widthScaleFactor,
      scaleY: isLocked
        ? selectedObject.scaleX * widthScaleFactor // Match scaleY with scaleX if locked
        : selectedObject.scaleY * heightScaleFactor, // Use independent scaleY if unlocked
    });

    // Update object's position and refresh canvas
    selectedObject.setCoords();
    fabricCanvasGlobal.requestRenderAll();
  };

  React.useEffect(() => {
    if (!fabricCanvasGlobal) return;

    let timeout;

    const handleObjectScaling = (e) => {
      console.log("BREAKPOINT parent listener");
      if (isChildListenerActive) return; // Skip if child listener is active

      const activeObject = e.target;

      if (activeObject && activeObject.type === "i-text") {
        clearTimeout(timeout);

        // Debounce to avoid multiple updates
        timeout = setTimeout(() => {
          const boundingRect = activeObject.getBoundingRect();
          const scaleFactor = fabricCanvasGlobal.getZoom() || 1;

          const updatedWidth = boundingRect.width / scaleFactor;
          const updatedHeight = boundingRect.height / scaleFactor;

          // Synchronize path scaling
          // const scaleFactorX = activeObject.scaleX;
          // const scaleFactorY = activeObject.scaleY;
          // if (selectedObject.path) {
          //   selectedObject.path.set({
          //     scaleX: scaleFactorX,
          //     scaleY: scaleFactorY,
          //   });

          //   // Keep the path and text aligned
          //   const boundingRect = selectedObject.getBoundingRect();
          //   const pathCenterX = boundingRect.left + boundingRect.width / 2;
          //   const pathCenterY = boundingRect.top + boundingRect.height / 2;

          //   selectedObject.path.set({
          //     left: pathCenterX,
          //     top: pathCenterY,
          //   });

          //   selectedObject.path.setCoords();
          // }

          // Calculate the updated fontSize based on the object's scale
          const updatedFontSize = activeObject.fontSize * activeObject.scaleX;

          // Reset scaleX and scaleY to 1 and apply the font size
          activeObject.set({
            scaleX: 1,
            scaleY: 1,
            fontSize: updatedFontSize,
          });

          setDimensions({
            width: updatedWidth,
            height: updatedHeight,
          });
          setWidth(updatedWidth);
          setHeight(updatedHeight);

          setTextSettings((prevSettings) => ({
            ...prevSettings,
            fontSize: updatedFontSize,
          }));

          // console.log(
          //   `Updated Width: ${updatedWidth}, Updated Height: ${updatedHeight}, Updated Font Size: ${updatedFontSize}`
          // );

          fabricCanvasGlobal.requestRenderAll();
        }, 100); // Debounce interval (100ms)
      }
    };

    // Add the scaling event listener
    if (!isChildListenerActive)
      fabricCanvasGlobal.on("object:scaling", handleObjectScaling);

    // Cleanup on component unmount
    return () => {
      clearTimeout(timeout);
      if (!isChildListenerActive)
        fabricCanvasGlobal.off("object:scaling", handleObjectScaling);
    };
  }, [fabricCanvasGlobal, isChildListenerActive]);

  React.useEffect(() => {
    handleTextSettingsChange(textSettings);
  }, [textSettings]);

  const alignObject = (alignment) => {
    // const activeObject = fabricCanvasGlobal.getActiveObject();

    if (!selectedObject) {
      alert("No object selected!");
      return;
    }

    const canvasWidth = fabricCanvasGlobal.width;
    const canvasHeight = fabricCanvasGlobal.height;
    // console.log("BREAKPOINT width = " + canvasWidth);
    // console.log("BREAKPOINT height = " + canvasHeight);

    switch (alignment) {
      case "horizontal-left":
        selectedObject.left = 0; // Align to left
        break;

      case "horizontal-center":
        selectedObject.left =
          artBoardSettings.width / 2 - selectedObject.getScaledWidth() / 2; // Center horizontally
        break;

      case "horizontal-right":
        selectedObject.left =
          artBoardSettings.width - selectedObject.getScaledWidth(); // Align to right
        break;

      case "vertical-top":
        selectedObject.top = 0; // Align to top
        break;

      case "vertical-center":
        selectedObject.top =
          artBoardSettings.height / 2 - selectedObject.getScaledHeight() / 2; // Center vertically
        break;

      case "vertical-bottom":
        selectedObject.top =
          artBoardSettings.height - selectedObject.getScaledHeight(); // Align to bottom
        break;

      default:
        break;
    }

    // Update the canvas and re-render
    selectedObject.setCoords(); // Update the object's coordinates
    fabricCanvasGlobal.requestRenderAll(); // Re-render the canvas
  };

  return (
    <Box
      sx={{
        padding: 2,
        width: "400px",
        maxHeight: "60vh",
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
        boxShadow: 1,
        overflowY: "auto",
        overflowX: "hidden",
        zIndex: 1,
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Text Settings
      </Typography>

      {/* Alignment Controls */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: 2,
        }}
      >
        {/* Horizontal Alignment */}
        <IconButton onClick={() => alignObject("horizontal-left")}>
          <AlignHorizontalLeftIcon />
        </IconButton>
        <IconButton onClick={() => alignObject("horizontal-center")}>
          <AlignHorizontalCenterIcon />
        </IconButton>
        <IconButton onClick={() => alignObject("horizontal-right")}>
          <AlignHorizontalRightIcon />
        </IconButton>

        {/* Vertical Alignment */}
        <IconButton onClick={() => alignObject("vertical-top")}>
          <AlignVerticalTopIcon />
        </IconButton>
        <IconButton onClick={() => alignObject("vertical-center")}>
          <AlignVerticalCenterIcon />
        </IconButton>
        <IconButton onClick={() => alignObject("vertical-bottom")}>
          <AlignVerticalBottomIcon />
        </IconButton>
      </Box>

      {/* Dimensions Controls */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          marginBottom: 2,
        }}
      >
        <TextField
          label="W"
          value={Math.floor(width)}
          onChange={(e) => handleInputChange("width", e.target.value)} // Update input state
          // onBlur={(e) => handleApplyDimension("width", e.target.value)} // Apply value on blur
          InputProps={{
            endAdornment: <InputAdornment position="end">px</InputAdornment>,
          }}
          size="small"
          sx={{ width: 100 }}
        />
        <TextField
          label="H"
          value={Math.floor(height)}
          onChange={(e) => handleInputChange("height", e.target.value)} // Update input state
          // onBlur={(e) => handleApplyDimension("height", e.target.value)} // Apply value on blur
          InputProps={{
            endAdornment: <InputAdornment position="end">px</InputAdornment>,
          }}
          size="small"
          sx={{ width: 100 }}
        />

        {/* Lock Icon */}
        <IconButton onClick={() => setIsLocked(!isLocked)}>
          {isLocked ? <Lock /> : <LockOpen />}
        </IconButton>
      </Box>

      <TextProperties
        currentSettings={textSettings} // Pass the current settings
        onSettingChange={handleTextSettingsChange} // Pass the function to update Fabric.js
        onInputChange={handleChange} // Pass the function to update local state
        selectedObject={selectedObject}
      ></TextProperties>
      {/* Font */}
      {/* <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel>Font</InputLabel>
        <Select
          value={textSettings.fontFamily}
          onChange={(e) => handleChange("fontFamily", e.target.value)}
        >
          <MenuItem value="Arial">Arial</MenuItem>
          <MenuItem value="Roboto">Roboto</MenuItem>
          <MenuItem value="Times New Roman">Times New Roman</MenuItem>
        </Select>
      </FormControl> */}

      {/* Font Size */}
      {/* <Typography variant="subtitle1">Font Size</Typography>
      <TextField
        type="number"
        value={textSettings.fontSize}
        onChange={(e) => handleChange("fontSize", parseInt(e.target.value))}
        fullWidth
        sx={{ marginBottom: 2 }}
      /> */}

      {/* Alignment */}
      {/* <Typography variant="subtitle1">Alignment</Typography>
      <ToggleButtonGroup
        value={textSettings.textAlign}
        exclusive
        onChange={(e, value) => handleChange("textAlign", value)}
        sx={{ marginBottom: 2 }}
        size="small"
      >
        <ToggleButton value="left">
          <FormatAlignLeft />
        </ToggleButton>
        <ToggleButton value="center">
          <FormatAlignCenter />
        </ToggleButton>
        <ToggleButton value="right">
          <FormatAlignRight />
        </ToggleButton>
      </ToggleButtonGroup> */}

      {/* <ToggleButtonGroup
        value={currentSettings.textTransform}
        exclusive
        onChange={(e, value) => handleChange("textTransform", value)}
        sx={{ marginBottom: 2 }}
        size="small"
      >
        <ToggleButton value="uppercase">UPPERCASE</ToggleButton>
        <ToggleButton value="lowercase">lowercase</ToggleButton>
        <ToggleButton value="capitalize">Capitalize</ToggleButton>
      </ToggleButtonGroup> */}

      {/* Colors */}
      <Typography variant="subtitle1">Fill Color</Typography>
      <TextField
        type="color"
        value={textSettings.fill}
        onChange={(e) => handleChange("fill", e.target.value)}
        fullWidth
        sx={{ marginBottom: 2 }}
      />

      {/* <Typography variant="subtitle1">Stroke Color</Typography>
      <TextField
        type="color"
        value={textSettings.stroke}
        onChange={(e) => handleChange("stroke", e.target.value)}
        fullWidth
        sx={{ marginBottom: 2 }}
      /> */}

      {/* Stroke Width */}
      {/* <Typography variant="subtitle1">Stroke Width</Typography>
      <Slider
        aria-labelledby="input-slider"
        value={textSettings.strokeWidth || 0}
        onChange={(e, value) => handleChange("strokeWidth", value)}
        min={0}
        max={10}
        step={0.1}
        sx={{ marginBottom: 2 }}
        valueLabelDisplay="auto"
      /> */}

      {/* Opacity */}
      <Typography variant="subtitle1">Opacity</Typography>
      <Slider
        value={textSettings.opacity || 100}
        onChange={(e, value) => handleChange("opacity", value)}
        min={0}
        max={100}
        step={1}
        valueLabelDisplay="auto"
        sx={{ marginBottom: 2 }}
      />

      {/* Text Transformations */}
      {/* <Typography variant="subtitle1">Text Transformations</Typography> */}
      <TextBorderComponent
        selectedObject={selectedObject}
        fabricCanvasGlobal={fabricCanvasGlobal}
        setIsChildListenerActive={setIsChildListenerActive}
        isChildListenerActive={isChildListenerActive}
      />
      <TextTransformationComponent
        selectedObject={selectedObject}
        fabricCanvasGlobal={fabricCanvasGlobal}
        setIsChildListenerActive={setIsChildListenerActive}
        isChildListenerActive={isChildListenerActive}
      />
      <TextShadingComponent
        selectedObject={selectedObject}
        fabricCanvasGlobal={fabricCanvasGlobal}
      />

      {/* Text Decorations */}
      {/* <Typography variant="subtitle1">Text Decorations</Typography> */}

      {/* Text Shading (Optional Placeholder) */}
      {/* <Typography variant="subtitle1">Text Shading</Typography> */}
      <Box
        sx={{
          display: "flex",
          gap: 1,
          marginBottom: 2,
          alignItems: "center",
        }}
      >
        {/* <Typography variant="body2">X Offset:</Typography>
        <TextField
          type="number"
          value={currentSettings.shadowOffsetX || 0}
          onChange={(e) =>
            handleChange("shadowOffsetX", parseInt(e.target.value))
          }
          sx={{ width: "60px" }}
        />
        <Typography variant="body2">Y Offset:</Typography>
        <TextField
          type="number"
          value={currentSettings.shadowOffsetY || 0}
          onChange={(e) =>
            handleChange("shadowOffsetY", parseInt(e.target.value))
          }
          sx={{ width: "60px" }}
        />
        <Typography variant="body2">Blur:</Typography>
        <TextField
          type="number"
          value={currentSettings.shadowBlur || 0}
          onChange={(e) => handleChange("shadowBlur", parseInt(e.target.value))}
          sx={{ width: "60px" }}
        />
        <Typography variant="body2">Color:</Typography>
        <TextField
          type="color"
          value={currentSettings.shadowColor || "#000000"}
          onChange={(e) => handleChange("shadowColor", e.target.value)}
          sx={{ width: "60px" }}
        /> */}
      </Box>
    </Box>
  );
};

export default TextSettings;
