import { useEffect } from "react";
import { backgroundRemoverREMBGGoogle } from "../image-processing-tools";

//font resizing part
export const useFontSizingEffect = (
  fontSizing,
  fabricCanvas,
  textObjectRef
) => {
  useEffect(() => {
    if (fabricCanvas && fabricCanvas.getContext()) {
      if (textObjectRef.current) {
        // Directly update the text object properties based on textDetails
        textObjectRef.current.set({
          fontSize: fontSizing,
          // strokeWidth: fontSizing / 25, // Stroke width

          // Set other properties based on textDetails as needed
        });

        fabricCanvas.renderAll(); // Re-render canvas to apply the text object updates
        return () => {
          //   isMounted = false;
          //   if (!isMounted) {
          //     // setFont("Anton");
          //     // setFontSizing(500);
          //     // console.log("BREAKPOINT INSIDE the return useEffect = " + font);
          //   }
        };
      }
    }
  }, [fontSizing]);
};

//text color
export const useTextColorEffect = (textColor, fabricCanvas, textObjectRef) => {
  useEffect(() => {
    if (fabricCanvas && fabricCanvas.getContext()) {
      if (textObjectRef.current) {
        // Directly update the text object properties based on textDetails
        textObjectRef.current.set({
          fill: textColor,

          // Set other properties based on textDetails as needed
        });

        fabricCanvas.renderAll(); // Re-render canvas to apply the text object updates
        return () => {
          //   isMounted = false;
          //   if (!isMounted) {
          //     // setFont("Anton");
          //     // setFontSizing(500);
          //     // console.log("BREAKPOINT INSIDE the return useEffect = " + font);
          //   }
        };
      }
    }
  }, [textColor]);
};

export const useFontEffect = (font, fabricCanvas, textObjectRef) => {
  useEffect(() => {
    if (fabricCanvas && fabricCanvas.getContext()) {
      if (textObjectRef.current) {
        // Directly update the text object properties based on textDetails
        textObjectRef.current.set({
          fontFamily: font,
        });
        fabricCanvas.renderAll(); // Re-render canvas to apply the text object updates
        return () => {
          //   isMounted = false;
          //   if (!isMounted) {
          //     // setFont("Anton");
          //     // setFontSizing(500);
          //     // console.log("BREAKPOINT INSIDE the return useEffect = " + font);
          //   }
        };
      }
    }
  }, [font]);
};

export const useFormatEffect = (formats, fabricCanvas, textObjectRef) => {
  useEffect(() => {
    if (fabricCanvas && fabricCanvas.getContext()) {
      if (textObjectRef.current) {
        // Directly update the text object properties based on textDetails
        textObjectRef.current.set({
          fontWeight: formats.includes("bold") ? "bold" : "normal",
          fontStyle: formats.includes("italic") ? "italic" : "normal",
          underline: formats.includes("underlined") ? true : false,

          // Set other properties based on textDetails as needed
        });
        fabricCanvas.renderAll(); // Re-render canvas to apply the text object updates
        return () => {
          //   isMounted = false;
          //   if (!isMounted) {
          //     // setFont("Anton");
          //     // setFontSizing(500);
          //     // console.log("BREAKPOINT INSIDE the return useEffect = " + font);
          //   }
        };
      }
    }
  }, [formats]);
};

export const useTextEffect = (
  text,
  fabricCanvas,
  textObjectRef,
  canvasWidth,
  canvasHeight
) => {
  useEffect(() => {
    if (fabricCanvas && fabricCanvas.getContext()) {
      if (textObjectRef.current) {
        // Directly update the text object properties based on textDetails
        // Update existing text object
        textObjectRef.current.set({
          text: text,
        });
        textObjectRef.current.set({
          left: canvasWidth / 2 - textObjectRef.current.getScaledWidth() / 2,
          // top: 0,
        });
        fabricCanvas.renderAll(); // Re-render canvas to apply the text object updates
        return () => {
          //   isMounted = false;
          //   if (!isMounted) {
          //     // setFont("Anton");
          //     // setFontSizing(500);
          //     // console.log("BREAKPOINT INSIDE the return useEffect = " + font);
          //   }
        };
      }
    }
  }, [text]);
};

export const adjustCanvasSize = (fabricCanvas, props) => {
  if (!fabricCanvas) return; // Check if fabricCanvas is defined

  let viewportWidth = window.innerWidth;
  let viewportHeight = window.innerHeight;

  // Adjust canvas size based on device types
  if (viewportWidth < 600) {
    viewportWidth = window.innerWidth * props.canvasZoomRationMobile;
    viewportHeight = window.innerHeight * props.canvasZoomRationMobile;
  } else if (viewportWidth >= 600 && viewportWidth < 960) {
    viewportWidth = window.innerWidth * props.canvasZoomRationTablet;
    viewportHeight = window.innerHeight * props.canvasZoomRationTablet;
  } else if (viewportWidth >= 1500) {
    viewportWidth = window.innerWidth * props.canvasZoomRationDesktopXL;
    viewportHeight = window.innerHeight * props.canvasZoomRationDesktopXL;
  } else if (viewportWidth >= 960 && viewportWidth < 1500) {
    viewportWidth = window.innerWidth * props.canvasZoomRationDesktop;
    viewportHeight = window.innerHeight * props.canvasZoomRationDesktop;
  }

  // Calculate the scale factor
  const scaleX = viewportWidth / fabricCanvas.width;
  const scaleY = viewportHeight / fabricCanvas.height;
  const scaleToFit = Math.min(scaleX, scaleY);

  //   setGlobalZoom(scaleToFit);
  // Set canvas zoom
  fabricCanvas.setZoom(scaleToFit);

  // Update canvas element size to match the scaled drawing area
  fabricCanvas.setWidth(fabricCanvas.width * scaleToFit);
  fabricCanvas.setHeight(fabricCanvas.height * scaleToFit);
};

// export const adjustCanvasZooming = (fabricCanvas) => {
//   if (!fabricCanvas) return; // Check if fabricCanvas is defined

//   let viewportWidth = window.innerWidth * 0.7;
//   let viewportHeight = window.innerHeight * 0.7;

//   // Calculate the scale factor
//   const scaleX = viewportWidth / fabricCanvas.width;
//   const scaleY = viewportHeight / fabricCanvas.height;
//   const scaleToFit = Math.min(scaleX, scaleY);

//   //   setGlobalZoom(scaleToFit);
//   // Set canvas zoom
//   fabricCanvas.setZoom(scaleToFit);

//   // Update canvas element size to match the scaled drawing area
//   fabricCanvas.setWidth(fabricCanvas.width * scaleToFit);
//   fabricCanvas.setHeight(fabricCanvas.height * scaleToFit);
// };

export const adjustCanvasZooming = (fabricCanvasGlobal, padding = 20) => {
  if (!fabricCanvasGlobal) {
    console.error("Canvas instance is not available.");
    return;
  }

  // Get the window dimensions
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  // Get the original dimensions of the canvas
  const originalWidth =
    fabricCanvasGlobal.originalWidth || fabricCanvasGlobal.width;
  const originalHeight =
    fabricCanvasGlobal.originalHeight || fabricCanvasGlobal.height;

  // Store the original dimensions if not already stored
  if (!fabricCanvasGlobal.originalWidth || !fabricCanvasGlobal.originalHeight) {
    fabricCanvasGlobal.originalWidth = originalWidth;
    fabricCanvasGlobal.originalHeight = originalHeight;
  }

  // Calculate aspect ratio of the canvas
  const aspectRatio = originalWidth / originalHeight;

  // Calculate the new width and height while maintaining the aspect ratio
  let newWidth = windowWidth - padding * 2;
  let newHeight = newWidth / aspectRatio;

  if (newHeight > windowHeight - padding * 2) {
    newHeight = windowHeight - padding * 2;
    newWidth = newHeight * aspectRatio;
  }

  // Resize the canvas
  fabricCanvasGlobal.setWidth(newWidth);
  fabricCanvasGlobal.setHeight(newHeight);

  // Scale content proportionally
  const scaleFactor = newWidth / originalWidth;
  fabricCanvasGlobal.setViewportTransform([
    scaleFactor,
    0,
    0,
    scaleFactor,
    0,
    0,
  ]);

  // Re-render the canvas
  fabricCanvasGlobal.requestRenderAll();
};

//image background remover
export const removeBG = async (
  blobURL,
  setLoading,
  retries = 10,
  delay = 100
) => {
  setLoading(true);
  let resultImageURL;

  try {
    resultImageURL = await backgroundRemoverREMBGGoogle(blobURL);
    setLoading(false);
  } catch (error) {
    if (error.name === "AbortError" && retries > 0) {
      console.log(`Timeout ERROR, retrying... (${retries} retries left)`);

      // Wait for the delay before retrying
      await new Promise((resolve) => setTimeout(resolve, delay));

      // Retry the function with one less retry available
      return removeBG(blobURL, setLoading, retries - 1, delay);
    } else {
      console.error(
        "Failed after retries or a non-timeout error occurred",
        error
      );
      setLoading(false);
    }
  }

  return resultImageURL;
};
