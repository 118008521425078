import React, { useEffect, useRef } from "react";
// import { Button, AppBar, Toolbar, Typography, IconButton } from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";
// import Header from "./Header";
// import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import MenuCanvas from "./MenuCanvas";
import Stack from "@mui/material/Stack";
// import Container from "@mui/material/Container";
import CanvasHeader from "./CanvasHeader";
import CanvasMenuItemImage from "./CanvasMenuItemImage";
import CanvasMenuItemTypography from "./CanvasMenuItemTypography";
import CanvasMenuGridList from "./CanvasMenuGridList";

import { useSelector, useDispatch } from "react-redux";
// import { updateCanvasMenu } from "./redux-slices/CanvasMenuSlice";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
// import ImageToCanvas from "./ImageToCanvas";
import CanvasArea from "./CanvasArea";

import {
  // oilPainting,
  // sketchStyle,
  // cartoonizerEffect,
  // watercolorEffect,
  // enhanceImage,
  // resizeImage,
  // removeBackgroundAI,
  // styleTransfer,
  // lineArt,
  // removeBackgroundAI2,
  cartoonizerEffect2,
  // styleTransfer2,
  convertToSketch2,
  convertToSketchOpencv,
  imageToVectorBlackAndWhite,
  backgroundRemoverREMBG,
} from "./image-processing-tools";
import { useMediaQuery } from "@mui/material";
import { unsetGridMenuClicked } from "./redux-slices/GridMenuClickedSlice";
import TemplateList from "./TemplateList";
import FloatingToolbar from "./FloatingToolbar";
import { Helmet } from "react-helmet";
import CanvasAreaForDesign from "./CanvasAreaV2";

// import { convertToGrayscale } from "./image-manipulations-tools";
// import { unsetGridMenuClicked } from "./redux-slices/GridMenuClickedSlice";

const artTempObject = [
  // { style: "Cartoonizer", handleFunction: cartoonizerEffect },
  { style: "Cartoonizer 2", handleFunction: cartoonizerEffect2 },
  // { style: "Oil Paint", handleFunction: oilPainting },
  // { style: "Watercolor", handleFunction: watercolorEffect },
  { style: "Sketch", handleFunction: convertToSketchOpencv },
  { style: "Sketch 2", handleFunction: convertToSketch2 },
  // { style: "Style Transfer", handleFunction: styleTransfer },
  // { style: "Style Transfer2", handleFunction: styleTransfer2 },
  // { style: "Line Art", handleFunction: lineArt },
  {
    style: "Vectorize Black & White",
    handleFunction: imageToVectorBlackAndWhite,
  },
  {
    style: "Vectorize Black & White 2",
    handleFunction: "",
  },
  {
    style: "Background Remover",
    handleFunction: backgroundRemoverREMBG,
  },
];
const editObject = [
  { style: "Image Enhancement", handleFunction: "" },
  { style: "Crop", handleFunction: "" },
  { style: "Image Resize", handleFunction: "" },
  { style: "Rotate", handleFunction: "" },
  // { style: "Background Remover", handleFunction: removeBackgroundAI },
  // { style: "Background Remover2", handleFunction: removeBackgroundAI2 },
  // { style: "Hue & Saturation", handleFunction: "" },
  // { style: "Exposure", handleFunction: "" },
  { style: "Grayscale", handleFunction: "" },
];

const templates = [
  { style: "Shirt Templates", handleFunction: "" },
  { style: "Shirt Templates 2", handleFunction: "" },
];
const Canvas = () => {
  const dispatch = useDispatch();
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // const dispatch = useDispatch();
  const selectedMenu = useSelector((state) => state.selectedMenu.value);
  // const gridMenuClicked = useSelector(
  //   (state) => state.gridMenuClicked.gridMenuClicked
  // ); // Access the image URL from the Redux store

  let canvasMenu;
  if (selectedMenu === "image") canvasMenu = <CanvasMenuItemImage />;
  // else if (selectedMenu === "text") canvasMenu = <CanvasMenuItemTypography />;
  else if (selectedMenu === "art")
    canvasMenu = <CanvasMenuGridList menu={artTempObject} />;
  else if (selectedMenu === "edit")
    canvasMenu = <CanvasMenuGridList menu={editObject} />;
  else if (selectedMenu === "templates")
    canvasMenu = <TemplateList menu={templates} />;
  const canvasRef = useRef(null);
  const gridItemRef = useRef(null);

  useEffect(() => {
    const resizeCanvas = () => {
      const gridItem = gridItemRef.current;
      const canvas = canvasRef.current;
      if (gridItem && canvas) {
        canvas.width = gridItem.offsetWidth;
        canvas.height = gridItem.offsetHeight;
      }
    };

    window.addEventListener("resize", resizeCanvas);
    resizeCanvas(); // Initial resize

    return () => window.removeEventListener("resize", resizeCanvas);
  }, []);

  //menu style panel for mobile dimensions
  let menuStyleElement;
  if (selectedMenu === "image") {
    menuStyleElement = (
      <Box
        sx={{
          position: "absolute",
          top: "-25vh",
          left: 0,
          right: 0,
          borderStyle: "outset",
          backgroundColor: "#777777",
          // opacity: "90%",
        }}
      >
        {canvasMenu}
      </Box>
    );
  } else if (selectedMenu === "edit") {
    menuStyleElement = (
      <Box
        sx={{
          position: "absolute",
          top: "-12vh",
          left: 0,
          right: 0,
          borderStyle: "outset",
          backgroundColor: "#777777",
          // opacity: "90%",
        }}
      >
        {canvasMenu}
      </Box>
    );
  } else if (selectedMenu === "art") {
    menuStyleElement = (
      <Box
        sx={{
          position: "absolute",
          top: "-10vh",
          left: 0,
          right: 0,
          borderStyle: "outset",
          backgroundColor: "#777777",
          // opacity: "90%",
        }}
      >
        {canvasMenu}
      </Box>
    );
  } else if (selectedMenu === "text") {
    menuStyleElement = (
      <Box
        sx={{
          position: "absolute",
          top: "-55vh",
          left: 0,
          right: 0,
          borderStyle: "outset",
          backgroundColor: "#777777",
          // opacity: "90%",
        }}
      >
        {canvasMenu}
      </Box>
    );
  } else if (selectedMenu === "templates") {
    menuStyleElement = (
      <Box
        sx={{
          position: "absolute",
          top: "-10vh",
          left: 0,
          right: 0,
          borderStyle: "outset",
          backgroundColor: "#777777",
          // opacity: "90%",
        }}
      >
        {canvasMenu}
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Helmet>
          <title>Canvas Design Tool | Okay Artist</title>
          <meta
            name="description"
            content="Create and edit your custom designs with Okay Artist's Canvas Tool. Add images, text, apply effects, and more."
          />
          <meta
            name="keywords"
            content="canvas design tool, custom designs, image editor, photo effects, okay artist, design templates, vectorize images, background remover"
          />
          <meta
            property="og:title"
            content="Canvas Design Tool | Okay Artist"
          />
          <meta
            property="og:description"
            content="Use the Okay Artist Canvas Design Tool to create and customize your artwork with advanced image editing options like cartoonizer, sketch effects, and vectorization."
          />
          <meta property="og:url" content="https://www.okayartist.com/canvas" />
          <meta
            property="og:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Canvas Design Tool | Okay Artist"
          />
          <meta
            name="twitter:description"
            content="Design and customize your artwork with Okay Artist's advanced Canvas Tool, offering a variety of effects and templates."
          />
          <meta
            name="twitter:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
        </Helmet>
        {/* <Header /> */}

        <Stack container direction={"column"} spacing={{ xs: 5, sm: 5, md: 5 }}>
          {/* <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6">Image Processing Canvas</Typography>
          </Toolbar>
        </AppBar> */}
          <CanvasHeader />

          <Box
            variant="outlined"
            square={false}
            sx={{ backgroundColor: "#535353" }}
          >
            {!matchesXS && (
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
                sx={{ backgroundColor: "red" }}
                // alignItems="stretch"
                // height={"80vh"}

                // margin={"0"}
              >
                <Grid
                  item
                  xs={1}
                  sm={2}
                  md={2}
                  lg={2}
                  backgroundColor={"#535353"}
                >
                  <Stack
                    alignItems={{ xs: "flex-start", md: "flex-start" }}
                    justifyContent={{ xs: "flex-start", md: "flex-start" }}
                    direction={{ xs: "column", sm: "row" }}
                  >
                    <MenuCanvas
                    // onClick={() => {
                    //   dispatch(unsetGridMenuClicked());
                    // }}
                    />

                    <Box sx={{ width: "80%", borderStyle: "outset" }}>
                      {/* <CanvasMenuItemImage /> */}
                      {canvasMenu}
                    </Box>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={6}
                  md={10}
                  lg={10}
                  backgroundColor="#282828"
                  // style={{ backgroundColor:"blue" }}
                  ref={gridItemRef}
                >
                  {/* <CanvasArea
                  // ref={canvasRef}
                  // id="imageCanvas"
                  ></CanvasArea> */}
                  <CanvasArea></CanvasArea>
                </Grid>
              </Grid>
            )}

            {/* menus for mobile dimension */}
            {matchesXS && (
              <Stack container direction="column">
                <Box>
                  <CanvasArea
                  // ref={canvasRef}
                  // id="imageCanvas"
                  ></CanvasArea>
                </Box>
                <Stack
                  sx={{ position: "relative", backgroundColor: "#777777" }}

                  // alignItems={{ xs: "flex-start", md: "flex-start" }}
                  // justifyContent={{ xs: "flex-start", md: "flex-start" }}
                  // direction={{ xs: "column", sm: "row" }}
                >
                  {/* Menu item panel*/}

                  {menuStyleElement}
                  {/* {selectedMenu === "image" && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "-20vh",
                        left: 0,
                        right: 0,
                        borderStyle: "outset",
                        backgroundColor: "#777777",
                        // opacity: "90%",
                      }}
                    >
                      {canvasMenu}
                    </Box>
                  )}
                  {selectedMenu === "edit" && (
                    <Box
                      sx={{
                        position: "absolute",
                        top: "-8vh",
                        left: 0,
                        right: 0,
                        borderStyle: "outset",
                        backgroundColor: "#777777",
                        // opacity: "90%",
                      }}
                    >
                      {canvasMenu}
                    </Box>
                  )} */}

                  <MenuCanvas />
                </Stack>
              </Stack>
            )}
          </Box>
        </Stack>
      </Box>
    </ThemeProvider>
  );
};
export default Canvas;
