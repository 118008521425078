import React from "react";
import {
  Box,
  Select,
  MenuItem,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  InputAdornment,
  Typography,
  FormControl,
  selectClasses,
} from "@mui/material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";

const TextProperties = ({
  currentSettings,
  onSettingChange,
  onInputChange,
  selectedObject,
}) => {
  const [localSettings, setLocalSettings] = React.useState(currentSettings);

  // Sync local state with the prop
  React.useEffect(() => {
    setLocalSettings(currentSettings);
  }, [currentSettings]);

  const handleInputChange = (key, value) => {
    onInputChange(key, value); // Call parent function to update state
    setLocalSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
  };

  const handleApplySettings = () => {
    onSettingChange(localSettings); // Apply settings to Fabric.js object
  };

  const [fontFamily, setFontFamily] = React.useState("Roboto");
  const [fontWeight, setFontWeight] = React.useState("Bold");
  const [fontSize, setFontSize] = React.useState(108);
  const [letterSpacing, setLetterSpacing] = React.useState(0);
  const [lineHeight, setLineHeight] = React.useState(40);
  const [textAlign, setTextAlign] = React.useState("center");
  const [formats, setFormats] = React.useState(() => [
    currentSettings.fontWeight,
    currentSettings.fontStyle,
    currentSettings.underline ? "underlined" : "",
  ]);

  const handleFormat = (event, newFormats) => {
    // console.log("BREAKPOINT formats= " + newFormats);
    // onInputChange(key, value);

    newFormats.includes("bold")
      ? onInputChange("fontWeight", "bold")
      : onInputChange("fontWeight", "normal");

    newFormats.includes("italic")
      ? onInputChange("fontStyle", "italic")
      : onInputChange("fontStyle", "normal");

    newFormats.includes("underlined")
      ? onInputChange("underline", true)
      : onInputChange("underline", false);
    // newFormats.includes("italic") ? "italic" : "normal",
    //   newFormats.includes("underline"),
    setFormats(newFormats);
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: 2,
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
      }}
    >
      {/* Header */}
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: "bold", marginBottom: 1 }}
      >
        Text
      </Typography>

      {/* Font Settings */}
      <Box sx={{ display: "flex", gap: 1, marginBottom: 2 }}>
        {/* Font Family */}
        <FormControl fullWidth>
          <Select
            value={localSettings.fontFamily}
            onChange={(e) => handleInputChange("fontFamily", e.target.value)}
            displayEmpty
          >
            <MenuItem value="Roboto">Roboto</MenuItem>
            <MenuItem value="Arial">Arial</MenuItem>
            <MenuItem value="Times New Roman">Times New Roman</MenuItem>
          </Select>
        </FormControl>

        {/* Font Weight */}
        <FormControl fullWidth>
          <Select
            value={localSettings.fontWeight || "normal"}
            onChange={(e) => handleInputChange("fontWeight", e.target.value)}
            displayEmpty
          >
            <MenuItem value="bold">Bold</MenuItem>
            <MenuItem value="normal">Regular</MenuItem>
            {/* <MenuItem value={10}>Light</MenuItem> */}
          </Select>
        </FormControl>
      </Box>

      {/* Font Size, Letter Spacing, Line Height */}
      <Box sx={{ display: "flex", gap: 1, marginBottom: 2 }}>
        {/* Font Size */}
        <TextField
          label="Tt"
          type="number"
          value={Math.floor(localSettings.fontSize)}
          onChange={(e) =>
            handleInputChange("fontSize", parseInt(e.target.value))
          }
          InputProps={{
            endAdornment: <InputAdornment position="end">px</InputAdornment>,
          }}
          size="small"
        />

        {/* Letter Spacing */}
        <TextField
          label="AV"
          type="number"
          value={localSettings.charSpacing}
          onChange={(e) =>
            handleInputChange("charSpacing", parseInt(e.target.value))
          }
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          size="small"
        />

        {/* Line Height */}
        <TextField
          label="AA"
          type="number"
          value={localSettings.lineHeight}
          onChange={(e) =>
            handleInputChange("lineHeight", parseFloat(e.target.value))
          }
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            inputProps: { step: 0.1 },
          }}
          size="small"
        />
      </Box>

      {/* Text Alignment */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <ToggleButtonGroup
          value={localSettings.textAlign}
          exclusive
          onChange={(e, value) => handleInputChange("textAlign", value)}
          size="small"
        >
          <ToggleButton value="left">
            <FormatAlignLeftIcon />
          </ToggleButton>
          <ToggleButton value="center">
            <FormatAlignCenterIcon />
          </ToggleButton>
          <ToggleButton value="right">
            <FormatAlignRightIcon />
          </ToggleButton>
        </ToggleButtonGroup>

        {/* Text Transform and Font Features */}
        <ToggleButtonGroup
          //   value={localSettings.fontStyle}
          value={formats}
          onChange={handleFormat}
          //   exclusive
          //   onChange={(e, value) => handleInputChange("fontStyle", value)}
          size="small"
          aria-label="text formatting"
        >
          <ToggleButton value="bold" aria-label="bold">
            <FormatBoldIcon />
          </ToggleButton>
          <ToggleButton value="italic" aria-label="italic">
            <FormatItalicIcon />
          </ToggleButton>
          <ToggleButton value="underlined" aria-label="underlined">
            <FormatUnderlinedIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default TextProperties;
