import React, { useRef, useState, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  Slider,
  TextField,
  InputAdornment,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { fabric } from "fabric";
import { CanvasContext } from "../CanvasContext";

const TextTransformationComponent = ({
  selectedObject,
  fabricCanvasGlobal,
  setIsChildListenerActive,
  isChildListenerActive,
}) => {
  const {
    isOpenTextTransformation,
    setIsOpenTextTransformation,
    activeTransformation,
    setActiveTransformation,
    artBoardSettings,
    angleCurve,
    setAngleCurve,
    skewXValue,
    setSkewXValue,
    offset,
    setOffset,
    transformationConfirmed,
    setTransformationConfirmed,
    // controlPoints,
    // setControlPoints,
  } = useContext(CanvasContext);
  // const [isOpen, setIsOpen] = useState(false); // State to toggle open/close
  // const [angleCurve, setAngleCurve] = useState(80); // State for slider value
  // const [activeTransformation, setActiveTransformation] = useState(null); // Tracks active button
  // const [pathText, setPathText] = useState(null);
  const pathRef = useRef(selectedObject.path); // Use ref for path

  const handleToggle = () => {
    setIsOpenTextTransformation(!isOpenTextTransformation);
  };

  // React.useEffect(() => {
  //   setSkewXValue(angleCurve / 4);
  // }, [angleCurve]);

  // React.useEffect(() => {
  //   console.log("BREAKPOINT skewXValue= " + skewXValue);
  //   selectedObject.set({ skewX: -skewXValue });
  // }, [skewXValue]);

  const handleAngleCurveChange = (event, newValue) => {
    setAngleCurve(newValue);
  };

  const handleOffsetChange = (event, newValue) => {
    setOffset(newValue);
  };

  // function calculatePathLength(fabricPath) {
  //   // Use SVG to calculate the path length
  //   const svgPath = document.createElementNS(
  //     "http://www.w3.org/2000/svg",
  //     "path"
  //   );
  //   svgPath.setAttribute("d", fabricPath.path.toString());
  //   return svgPath.getTotalLength();
  // }

  // function truncateTextToFitPath(text, fabricTextObject, fabricPath) {
  //   const charSpacing = fabricTextObject.charSpacing || 0; // Character spacing
  //   const fontSize = fabricTextObject.fontSize || 16; // Font size
  //   const scaleFactor = fabricTextObject.scaleX || 1; // Scale factor (if scaled)

  //   // Approximate the length of the text
  //   const textLength = text.length * (fontSize + charSpacing) * scaleFactor;

  //   // Calculate the path length
  //   const pathLength = calculatePathLength(fabricPath);

  //   // If text length exceeds path length, truncate
  //   if (textLength > pathLength) {
  //     const maxChars = Math.floor((pathLength / textLength) * text.length);
  //     return text.slice(0, maxChars) + "..."; // Truncate and add ellipsis
  //   }
  //   return text; // Text fits the path
  // }
  const getPathData = (fabricPath) => {
    if (!fabricPath || fabricPath.type !== "path") {
      console.error("The provided object is not a valid Fabric.js path.");
      return null;
    }

    // Extract the raw path array
    const rawPathData = fabricPath.path;

    // Convert it into a valid SVG path data string
    const svgPathData = rawPathData
      .map((segment) => segment.join(" "))
      .join(" ");

    return svgPathData;
  };

  const calculatePathLength = (svgPathData) => {
    // Create a temporary SVG element
    const svgNamespace = "http://www.w3.org/2000/svg";
    const svg = document.createElementNS(svgNamespace, "svg");
    const path = document.createElementNS(svgNamespace, "path");

    // Set the path's 'd' attribute
    path.setAttribute("d", svgPathData);

    // Append the path to the SVG element
    svg.appendChild(path);
    document.body.appendChild(svg); // Add it temporarily to the DOM

    // Measure the total length of the path
    const totalLength = path.getTotalLength();

    // Clean up by removing the SVG element
    document.body.removeChild(svg);

    return totalLength;
  };

  const centerTextAlongPath = (textObject, pathObject) => {
    if (!textObject || !pathObject) return;

    // Get the length of the path
    // Get the path length (use width as an approximation if getTotalLength is unavailable)
    // const pathLength = pathObject.getTotalLength
    //   ? pathObject.getTotalLength()
    //   : pathObject.getBoundingRect().width;
    // const pathLength = pathObject.width;
    const pathLength = calculatePathLength(getPathData(pathObject));

    // Measure the total width of the text
    const ctx = fabricCanvasGlobal.getContext();
    // Set the canvas context's font to match the Fabric.js text object
    ctx.font = `${textObject.fontWeight || "normal"} ${textObject.fontSize}px ${
      textObject.fontFamily || "Arial"
    }`;
    const textWidth = ctx.measureText(textObject.text).width;
    // const textWidth = textObject.width;

    // Calculate the offset to center the text
    const centerOffset = (pathLength - textWidth) / 2;
    console.log("BREAKPOINT textWidth= " + textWidth);
    console.log("BREAKPOINT pathLength= " + pathLength);
    console.log("BREAKPOINT centerOffset= " + centerOffset);

    // Update the text object's pathStartOffset
    textObject.set({
      pathStartOffset: centerOffset,
    });

    textObject.setCoords();

    fabricCanvasGlobal.requestRenderAll();
  };

  const renderTextWithBoundingRect = (textObject, pathObject) => {
    const ctx = fabricCanvasGlobal.getContext();
    const pathBoundingBox = pathObject.getBoundingRect();

    // Set the canvas context's font to match the Fabric.js text object
    ctx.font = `${textObject.fontWeight || "normal"} ${textObject.fontSize}px ${
      textObject.fontFamily || "Arial"
    }`;

    let currentText = "";
    let currentWidth = 0;
    console.log(
      "BREAKPOINT selectedObject.fontSize= " + selectedObject.fontSize
    );
    console.log("BREAKPOINT selectedObject.width= " + selectedObject.width);
    console.log("BREAKPOINT pathObject.width= " + pathObject.width);
    const scaleFactor = fabricCanvasGlobal.getZoom() || 1;

    for (let char of textObject.text) {
      // const charWidth = ctx.measureText(char).width;
      const intrinsicCharWidth = ctx.measureText(char).width;
      const scaledCharWidth = intrinsicCharWidth * scaleFactor;

      console.log(
        "BREAKPOINT char= " + char + ", scaledCharWidth= " + scaledCharWidth
      );
      // Check if adding the character exceeds the bounding rect
      if (currentWidth + scaledCharWidth > pathObject.width) break;

      currentText += char;
      currentWidth += scaledCharWidth;
    }
    console.log(
      "BREAKPOINT currentText= " +
        currentText +
        ", currentWidth= " +
        currentWidth +
        ", pathBoundingBox.width= " +
        pathBoundingBox.width
    );

    textObject.text = currentText; // Update the text
    fabricCanvasGlobal.requestRenderAll();
  };

  React.useEffect(() => {
    centerTextAlongPath(selectedObject, pathRef.current);
    console.log("BREAKPOINT useEffect pathRef.current=" + pathRef.current);
  }, [selectedObject.fontSize, selectedObject.fontFamily, pathRef.current]);

  React.useEffect(() => {
    handleTransformationClick(activeTransformation);
  }, [activeTransformation, angleCurve]);

  const handleTransformationClick = (option) => {
    console.log("COUNTER HANDLE CLICK");
    setActiveTransformation(option); // Set the clicked transformation as active
    // Apply transformation logic here based on the selected option
    if (option === "Circle") {
      // selectedObject.set({ skewX: 0, skewY: 0, scaleY: 1, scaleX: 1 });

      const centerX = 200; // X-coordinate of the circle center
      const centerY = 200; // Y-coordinate of the circle center

      // Function to generate the SVG path for the circle
      const generateCirclePath = (r) => {
        return `M ${centerX - r}, ${centerY}
                a ${r},${r} 0 1,0 ${r * 2},0
                a ${r},${r} 0 1,0 ${-r * 2},0`;
      };

      // Define a circular path using SVG path data
      const circularPath = new fabric.Path(
        generateCirclePath(Math.abs(5 * angleCurve))
      );
      // `M 150 150 m -100, 0 a ${angleCurve * 2},${
      //   angleCurve * 2
      // } 0 1,0 200,0 a ${angleCurve * 2},${angleCurve * 2} 0 1,0 -200,0`
      // Make the path invisible but usable
      circularPath.set({
        fill: "",
        // stroke: "#87CEEB",
        stroke: "",
        selectable: false,
      });
      //   setPathText(circularPath);
      // fabricCanvasGlobal.add(circularPath);

      selectedObject.set({
        path: circularPath, // Attach the path
        // pathStartOffset: 0, // Adjust the starting position along the path
        pathSide: "left", // 'left' or 'right' for positioning on the path
        pathAlign: "center", // 'center', 'baseline', or 'top'
        selectable: true,
      });
      pathRef.current = selectedObject.path; // Assign path to the ref
      // if (selectedObject) selectedObject.path.stroke = "#87CEEB";
      fabricCanvasGlobal.requestRenderAll();
    } else if (option === "Arch") {
      // selectedObject.set({ skewX: 0, skewY: 0, scaleY: 1, scaleX: 1 });
      // Define an arch path using SVG path data
      function mapSliderValue(x) {
        const oldMin = -100;
        const oldMax = 100;
        const newMin = -50;
        const newMax = 700;

        // Map the slider value
        return newMin + ((x - oldMin) / (oldMax - oldMin)) * (newMax - newMin);
      }
      let temp = artBoardSettings.width / 2;
      let point_i = { x: 0, y: mapSliderValue(angleCurve) };
      let point_f = { x: temp, y: angleCurve * 1.5 };
      // let temp = selectedObject.getScaledWidth();
      console.log("BREAKPOINT temp= " + temp);
      const archPath = new fabric.Path(
        // "M 0 450 A 50 50 0 1 1 700 450  " // SVG path for an arch
        // "M 100 300 Q 400 100 700 300"
        `M 0 350 Q 400 ${point_i.y} ${temp} 350`
        // `M 0 450 A 50 50 0 1 1 ${temp} 450`
      );
      // Make the path invisible but usable
      archPath.set({
        fill: "",
        stroke: "",
        selectable: false,
      });

      // Set the path and add to canvas
      selectedObject.set({
        path: archPath,
        pathAlign: "center",
        // pathStartOffset: 0,
        pathSide: "left",
      });

      // renderTextWithBoundingRect(selectedObject, archPath);
      centerTextAlongPath(selectedObject, archPath);

      // Update the text object to reflect changes
      selectedObject.setCoords();

      pathRef.current = selectedObject.path; // Assign path to the ref

      fabricCanvasGlobal.requestRenderAll();
    } else if (option === "Angle") {
      // selectedObject.set({ skewX: 0, skewY: 0, scaleY: 1, scaleX: 1 });
      // Define an arch path using SVG path data
      let temp = artBoardSettings.width / 2;
      let point_i = { x: 0, y: angleCurve * 3 };
      let point_f = { x: temp, y: angleCurve * 1.5 };
      // let temp = selectedObject.getScaledWidth();
      console.log("BREAKPOINT temp= " + temp);
      const risingPath = new fabric.Path(
        // "M 0 450 A 50 50 0 1 1 700 450  " // SVG path for an arch
        // "M 100 300 Q 400 100 700 300"
        `M ${point_i.x} ${point_i.y} L ${point_f.x} ${point_f.y}`
        // `M 0 450 A 50 50 0 1 1 ${temp} 450`
      );
      // Make the path invisible but usable
      risingPath.set({
        fill: "",
        stroke: "",
        selectable: false,
      });

      // Set the path and add to canvas
      selectedObject.set({
        path: risingPath,
        pathAlign: "center",
        pathStartOffset: offset,
        pathSide: "left",
        skewY: angleCurve / 5,
      });

      // renderTextWithBoundingRect(selectedObject, archPath);
      centerTextAlongPath(selectedObject, risingPath);

      // Update the text object to reflect changes
      selectedObject.setCoords();

      pathRef.current = selectedObject.path; // Assign path to the ref

      fabricCanvasGlobal.requestRenderAll();
    } else if (option === "Rise") {
      // selectedObject.set({ skewX: 0, skewY: 0, scaleX: 1, scaleY: 1 });
      // Define an arch path using SVG path data
      let temp = artBoardSettings.width / 2;
      let point_i = { x: 0, y: 300 };
      let point_f = { x: temp, y: angleCurve + 300 };
      // let temp = selectedObject.getScaledWidth();
      console.log("BREAKPOINT temp= " + temp);
      const archPath = new fabric.Path(
        // "M 0 450 A 50 50 0 1 1 700 450  " // SVG path for an arch
        // "M 100 300 Q 400 100 700 300"
        `M ${point_i.x} ${point_i.y} C 400 300 400 ${point_f.y} ${point_f.x} ${point_f.y}`
        // `M 0 450 A 50 50 0 1 1 ${temp} 450`
      );
      // Make the path invisible but usable
      archPath.set({
        fill: "",
        stroke: "",
        selectable: false,
      });

      // Set the path and add to canvas
      selectedObject.set({
        path: archPath,
        pathAlign: "center",
        // pathStartOffset: 0,
        pathSide: "left",
        skewX: angleCurve / 5,
      });

      // renderTextWithBoundingRect(selectedObject, archPath);
      centerTextAlongPath(selectedObject, archPath);

      // Update the text object to reflect changes
      selectedObject.setCoords();

      pathRef.current = selectedObject.path; // Assign path to the ref

      fabricCanvasGlobal.requestRenderAll();
    } else if (option === "Wave") {
      // selectedObject.set({ skewX: 0, skewY: 0, scaleY: 1, scaleX: 1 });
      function mapSliderValue(x) {
        const oldMin = -100;
        const oldMax = 100;
        const newMin = 100;
        const newMax = 600;

        // Map the slider value
        return newMin + ((x - oldMin) / (oldMax - oldMin)) * (newMax - newMin);
      }
      function mapSliderValue2(x) {
        const oldMin = -100;
        const oldMax = 100;
        const newMin = 600;
        const newMax = 100;

        // Map the slider value
        return newMin + ((x - oldMin) / (oldMax - oldMin)) * (newMax - newMin);
      }

      // Define an arch path using SVG path data
      let temp = artBoardSettings.width / 2;
      let point_i = { x: 0, y: mapSliderValue(angleCurve) };
      let point_f = { x: temp, y: angleCurve + 300 };

      let controlPoints = [
        { x: 50, y: 300 }, // Starting point
        { x: 150, y: mapSliderValue(angleCurve) }, // Control point for first curve
        { x: 600, y: mapSliderValue2(angleCurve) }, // End spoint of first curve, start of next
        // { x: 350, y: 350 }, // Control point for second curve
        { x: temp, y: 300 }, // End point of second curve
      ];
      // let temp = selectedObject.getScaledWidth();
      console.log("BREAKPOINT temp= " + temp);
      let wavePath = new fabric.Path(
        // "M 0 450 A 50 50 0 1 1 700 450  " // SVG path for an arch
        // "M 100 300 Q 400 100 700 300"
        `M ${controlPoints[0].x} ${controlPoints[0].y} ` +
          `C ${controlPoints[1].x} ${controlPoints[1].y} ${controlPoints[2].x} ${controlPoints[2].y} ` +
          `${controlPoints[3].x} ${controlPoints[3].y}`
        // `M 0 450 A 50 50 0 1 1 ${temp} 450`
      );
      // Make the path invisible but usable
      wavePath.set({
        fill: "",
        stroke: "",
        selectable: false,
        left: selectedObject.left,
        top: selectedObject.top,
      });

      // fabricCanvasGlobal.add(wavePath);

      // Set the path and add to canvas
      selectedObject.set({
        path: wavePath,
        pathAlign: "center",
        // pathStartOffset: 0,
        pathSide: "left",
        skewX: -angleCurve / 4,
        scaleY: 1.5,
      });

      let difLeftX = selectedObject.left - controlPoints[0].x;
      let difLeftY = selectedObject.top - controlPoints[0].y;
      // Adjust the path's position so `left` and `top` match the starting point
      // const boundingBox = selectedObject.getBoundingRect();
      // const offsetX = controlPoints[0].x - boundingBox.left;
      // const offsetY = controlPoints[0].y - boundingBox.top;
      // console.log("BREAKPOINT difLeft= " + difLeftX);
      // console.log("BREAKPOINT selectedObject.left= " + selectedObject.left);
      // console.log(
      //   "BREAKPOINT selectedObject.path.left= " + selectedObject.path.left
      // );

      // fabricCanvasGlobal.add(wavePath);

      // Extract control points from the path's SVG data

      // Add control points as draggable circles
      // const controlPointObjects = controlPoints.map((point, index) => {
      //   const circle = new fabric.Circle({
      //     left: point.x,
      //     top: point.y,
      //     radius: 10,
      //     fill: "red",
      //     stroke: "black",
      //     strokeWidth: 1,
      //     hasControls: false,
      //     hasBorders: false,
      //     selectable: true,
      //   });

      //   circle.on("moving", () => {
      //     // Update the path when the control point moves
      //     controlPoints[index] = { x: circle.left, y: circle.top };
      //     // setControlPoints((prevPoints) => {
      //     //   const newPoints = [...prevPoints];
      //     //   newPoints[index] = { x: circle.left, y: circle.top }; // Update the second control point
      //     //   return newPoints;
      //     // });
      //     updatePath(circle);
      //   });

      //   fabricCanvasGlobal.add(circle);
      //   return circle;
      // });

      // Function to update the path based on control points
      // function updatePath(circle) {
      //   const newPathData =
      //     `M ${controlPoints[0].x} ${controlPoints[0].y} ` +
      //     `C ${controlPoints[1].x} ${controlPoints[1].y} ${controlPoints[2].x} ${controlPoints[2].y} ` +
      //     `${controlPoints[3].x} ${controlPoints[3].y}`;

      //   // fabricCanvasGlobal.remove(wavePath);
      //   // Remove the old path from the canvas
      //   // fabricCanvasGlobal.remove(wavePath);
      //   // Update the path data of the existing path
      //   // wavePath.set({ path: newPathData });
      //   // Update the path of the text object

      //   // Create a new path with the updated path data
      //   wavePath = new fabric.Path(newPathData, {
      //     fill: "",
      //     stroke: "blue",
      //     selectable: false,
      //   });
      //   selectedObject.set({ path: wavePath });

      //   // circle.set({ left: selectedObject.left - controlPoints[0].x });

      //   // Add the updated path to the canvas and reassign the reference
      //   // fabricCanvasGlobal.add(wavePath);
      //   // Update the path of the text object
      //   // selectedObject.set({ path: wavePath });
      //   // wavePath.set({ path: newPathData });

      //   // Add the new path to the canvas
      //   // fabricCanvasGlobal.add(wavePath);
      //   // wavePath.set({ path: newPathData });

      //   // wavePath.set({ path: newPathData });
      //   console.log("BREAKPOINT newPathData= " + newPathData);
      //   // console.log("BREAKPOINT circle= " + JSON.stringify(circle, null, 2));
      //   // wavePath.setCoords();
      //   fabricCanvasGlobal.renderAll();
      // }

      console.log(
        "BREAKPOINT selectedObject.path= " +
          JSON.stringify(selectedObject.path, null, 2)
      );

      console.log(
        "BREAKPOINT selectedObject= " + JSON.stringify(selectedObject, null, 2)
      );
      // Function to sync control points to the path
      // function syncControlPoints() {
      //   controlPointObjects.forEach((circle, index) => {
      //     circle.set({
      //       left: controlPoints[index].x,
      //       top: controlPoints[index].y,
      //     });
      //     circle.setCoords();
      //   });
      // }

      // // Optional: Add an event to update control points if needed
      // fabricCanvasGlobal.on("object:modified", () => {
      //   syncControlPoints();
      // });

      // renderTextWithBoundingRect(selectedObject, archPath);
      centerTextAlongPath(selectedObject, wavePath);

      // Update the text object to reflect changes
      selectedObject.setCoords();

      pathRef.current = selectedObject.path; // Assign path to the ref

      fabricCanvasGlobal.requestRenderAll();
    } else if (option === "Flag") {
      // selectedObject.set({ skewX: 0, skewY: 0, scaleX: 1, scaleY: 1 });
      function mapSliderValue(x) {
        const oldMin = -100;
        const oldMax = 100;
        const newMin = 50;
        const newMax = 550;

        // Map the slider value
        return newMin + ((x - oldMin) / (oldMax - oldMin)) * (newMax - newMin);
      }
      function mapSliderValue2(x) {
        const oldMin = -100;
        const oldMax = 100;
        const newMin = 550;
        const newMax = 50;

        // Map the slider value
        return newMin + ((x - oldMin) / (oldMax - oldMin)) * (newMax - newMin);
      }

      // Define an arch path using SVG path data
      let temp = artBoardSettings.width / 2;
      let point_i = { x: 50, y: 300 };
      let point_f = {
        x: mapSliderValue2(angleCurve),
        y: mapSliderValue(angleCurve),
      };
      // let temp = selectedObject.getScaledWidth();
      console.log("BREAKPOINT temp= " + temp);
      const archPath = new fabric.Path(
        // "M 0 450 A 50 50 0 1 1 700 450  " // SVG path for an arch
        // "M 100 300 Q 400 100 700 300"
        `M ${point_i.x} ${point_i.y} C 150 ${point_f.x} 600 ${point_f.y} 700 300`
        // `M 0 450 A 50 50 0 1 1 ${temp} 450`
      );
      // Make the path invisible but usable
      archPath.set({
        fill: "",
        stroke: "",
        selectable: false,
      });

      // Set the path and add to canvas
      selectedObject.set({
        path: archPath,
        pathAlign: "center",
        // pathStartOffset: 0,
        pathSide: "left",
        skewX: angleCurve / 5,
      });

      // renderTextWithBoundingRect(selectedObject, archPath);
      centerTextAlongPath(selectedObject, archPath);

      // Update the text object to reflect changes
      selectedObject.setCoords();

      pathRef.current = selectedObject.path; // Assign path to the ref

      fabricCanvasGlobal.requestRenderAll();
    }
  };

  // Track the `pathText` state update
  // React.useEffect(() => {
  //   if (pathText) {
  //     console.log("PathText state updated:", pathText);
  //   }
  // }, [pathText]);

  React.useEffect(() => {
    if (activeTransformation == null) handleTransformationClick("Circle");
  }, []);

  React.useEffect(() => {
    if (!isOpenTextTransformation) {
      selectedObject.set({
        path: null,
        skewX: 0,
        skewY: 0,
        scaleX: 1,
        scaleY: 1,
      });
      pathRef.current = null; // Reset the path in the ref
      setActiveTransformation(null);
      setIsChildListenerActive(false);
    } else if (isOpenTextTransformation && activeTransformation == null) {
      setIsChildListenerActive(false);
    } else if (isOpenTextTransformation && activeTransformation != null) {
      setIsChildListenerActive(true);
      if (fabricCanvasGlobal.getActiveObject() == null)
        selectedObject.path.set({ stroke: "" });
    }
    fabricCanvasGlobal.requestRenderAll();
  }, [isOpenTextTransformation, activeTransformation]);

  React.useEffect(() => {
    // setIsChildListenerActive(true);
    if (!fabricCanvasGlobal) return;

    let timeout;

    const handleObjectScaling = (e) => {
      if (!isChildListenerActive) return;
      console.log("BREAKPOINT child listener");
      const activeObject = e.target;
      // console.log("BREAKPOINT pathText= " + pathText);

      if (activeObject && activeObject.type === "i-text") {
        clearTimeout(timeout);

        // Debounce to avoid multiple updates
        timeout = setTimeout(() => {
          const boundingRect = activeObject.getBoundingRect();
          const scaleFactor = fabricCanvasGlobal.getZoom() || 1;

          const updatedWidth = boundingRect.width / scaleFactor;
          const updatedHeight = boundingRect.height / scaleFactor;

          // Calculate the updated fontSize based on the object's scale
          const updatedFontSize = activeObject.fontSize * activeObject.scaleX;

          // If the text object has a path, synchronize the path scaling

          const path = pathRef.current; // Access the path via the ref
          console.log("BREAKPOINT path= " + path);
          if (path) {
            const pathBoundingRect = path.getBoundingRect();
            const pathScaleFactorX =
              boundingRect.width / pathBoundingRect.width;
            const pathScaleFactorY =
              boundingRect.height / pathBoundingRect.height;

            path.set({
              scaleX: pathScaleFactorX,
              scaleY: pathScaleFactorY,
            });

            const textCenterX = boundingRect.left + boundingRect.width / 2;
            const textCenterY = boundingRect.top + boundingRect.height / 2;

            path.set({
              left: textCenterX,
              top: textCenterY,
            });

            path.setCoords();

            // fabricCanvasGlobal.add(path);

            activeObject.set({
              path: path,
            });

            console.log(
              "activeObject Bounding Rect:",
              activeObject.path.getBoundingRect()
            );
            console.log("Path Bounding Rect:", path.getBoundingRect());

            // console.log(
            //   `Path Scaled to: ${pathScaleFactorX}, ${pathScaleFactorY}`
            // );
          }

          // Re-render the canvas
          fabricCanvasGlobal.requestRenderAll();
        }, 100); // Debounce interval (100ms)
      }
    };

    // Add the scaling event listener
    if (isChildListenerActive)
      fabricCanvasGlobal.on("object:scaling", handleObjectScaling);

    // Cleanup on component unmount
    return () => {
      console.log("EXITING COMPONENT");
      clearTimeout(timeout);
      if (isChildListenerActive)
        fabricCanvasGlobal.off("object:scaling", handleObjectScaling);
    };
  }, [fabricCanvasGlobal, setIsChildListenerActive]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 400,
        padding: 2,
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
        boxShadow: 1,
      }}
    >
      {/* Header with Toggle Button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography variant="h6">Transformation</Typography>
        <IconButton onClick={handleToggle}>
          {isOpenTextTransformation ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Box>

      {/* Content (conditionally rendered) */}
      {isOpenTextTransformation && (
        <>
          {/* Transformation Options */}
          <Grid container spacing={1} sx={{ marginBottom: 2 }}>
            {[
              // "Custom",
              // "Distort",
              "Circle",
              "Angle",
              "Arch",
              "Rise",
              "Wave",
              "Flag",
            ].map((option) => (
              <Grid item xs={3} key={option}>
                <Button
                  variant={
                    activeTransformation === option ? "text" : "outlined"
                  }
                  disabled={activeTransformation === option ? true : false}
                  //   variant="outlined"
                  //   color={activeTransformation === option ? "success" : "error"}
                  onClick={() => handleTransformationClick(option)}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    padding: "8px",
                    fontSize: "12px",
                  }}
                >
                  {option}
                </Button>
              </Grid>
            ))}
          </Grid>

          {/* Slider and Angle Curve */}
          <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
            {activeTransformation} Curve
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              marginBottom: 2,
            }}
          >
            <Slider
              value={angleCurve}
              onChange={handleAngleCurveChange}
              min={-100}
              max={100}
              sx={{ flex: 1 }}
            />
            <TextField
              value={angleCurve}
              onChange={(e) => setAngleCurve(parseInt(e.target.value) || 0)}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              size="small"
              sx={{ width: 80 }}
            />
          </Box>

          {/* Slider and Angle Curve */}
          {/* <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
            {activeTransformation} Offset
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              marginBottom: 2,
            }}
          >
            <Slider
              value={offset}
              onChange={handleOffsetChange}
              min={0}
              max={100}
              sx={{ flex: 1 }}
            />
            <TextField
              value={offset}
              onChange={(e) => setOffset(parseInt(e.target.value) || 0)}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              size="small"
              sx={{ width: 80 }}
            />
          </Box> */}

          {/* Confirm and Reset Buttons */}
          {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button variant="contained" color="primary">
              Confirm
            </Button>
            <Button variant="outlined">Reset Transform</Button>
          </Box> */}
        </>
      )}
    </Box>
  );
};

export default TextTransformationComponent;
