import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Popover,
  Stack,
  Divider,
} from "@mui/material";
import {
  DragIndicator,
  Delete,
  Visibility,
  VisibilityOff,
  Layers,
} from "@mui/icons-material";
import { CanvasContext } from "../CanvasContext";

const LayersComponent = ({ canvas }) => {
  const { fabricCanvasGlobal } = useContext(CanvasContext);
  const [layers, setLayers] = useState([]);
  const [draggedLayer, setDraggedLayer] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [mainAnchorEl, setMainAnchorEl] = useState(null);

  // Sync layers with canvas objects
  useEffect(() => {
    if (fabricCanvasGlobal) {
      const updateLayers = () => {
        const objects = fabricCanvasGlobal.getObjects();
        const newLayers = objects.map((object, index) => ({
          id: object.id || index,
          name: object.name || `Layer ${index + 1}`,
          visible: object.visible !== false, // Default to true if undefined
          object, // Reference to the Fabric.js object
        }));
        setLayers(newLayers.reverse()); // Reverse to match top-down order
      };

      updateLayers();

      // Listen for canvas changes
      fabricCanvasGlobal.on("object:added", updateLayers);
      fabricCanvasGlobal.on("object:removed", updateLayers);
      fabricCanvasGlobal.on("object:modified", updateLayers);

      return () => {
        fabricCanvasGlobal.off("object:added", updateLayers);
        fabricCanvasGlobal.off("object:removed", updateLayers);
        fabricCanvasGlobal.off("object:modified", updateLayers);
      };
    }
  }, [fabricCanvasGlobal]);

  // Handle drag start
  const handleDragStart = (layer) => {
    setDraggedLayer(layer);
  };

  // Handle drag over
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Handle drop
  const handleDrop = (targetLayer) => {
    const updatedLayers = [...layers];
    const draggedIndex = updatedLayers.findIndex(
      (l) => l.id === draggedLayer.id
    );
    const targetIndex = updatedLayers.findIndex((l) => l.id === targetLayer.id);

    // Rearrange layers
    const [draggedItem] = updatedLayers.splice(draggedIndex, 1);

    updatedLayers.splice(targetIndex, 0, draggedItem);

    setLayers(updatedLayers);

    // Update object stacking in Fabric.js canvas
    const objects = fabricCanvasGlobal.getObjects();
    updatedLayers.forEach((layer, index) => {
      fabricCanvasGlobal.moveTo(layer.object, objects.length - 1 - index);
    });

    setDraggedLayer(null);
  };

  // Handle Popover open
  const handlePopoverOpen = (event, layer) => {
    setAnchorEl(event.currentTarget);
    setSelectedLayer(layer);
  };

  // Handle Popover close
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedLayer(null);
  };

  // Toggle layer visibility
  const toggleVisibility = (layerId) => {
    const layer = layers.find((l) => l.id === layerId);
    if (layer) {
      layer.object.visible = !layer.visible;
      fabricCanvasGlobal.renderAll();
      setLayers((prev) =>
        prev.map((l) => (l.id === layerId ? { ...l, visible: !l.visible } : l))
      );
    }
  };

  // Remove layer
  const removeLayer = (layerId) => {
    const layer = layers.find((l) => l.id === layerId);
    if (layer) {
      fabricCanvasGlobal.remove(layer.object);
      handlePopoverClose();
    }
  };

  // Main popover controls
  const handleMainPopoverOpen = (event) => {
    setMainAnchorEl(event.currentTarget);
  };

  const handleMainPopoverClose = () => {
    setMainAnchorEl(null);
  };

  const mainOpen = Boolean(mainAnchorEl);

  return (
    <Box>
      <Button
        variant="contained"
        startIcon={<Layers />}
        onClick={handleMainPopoverOpen}
      >
        Layers
      </Button>
      <Popover
        open={mainOpen}
        anchorEl={mainAnchorEl}
        onClose={handleMainPopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            width: "300px",
            backgroundColor: "#f4f4f4",
            padding: 2,
            borderRadius: 2,
            boxShadow: 1,
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Layers
          </Typography>
          <Divider sx={{ marginBottom: 2 }} />
          <Stack spacing={1}>
            {layers.map((layer) => (
              <Box
                key={layer.id}
                draggable
                onDragStart={() => handleDragStart(layer)}
                onDragOver={handleDragOver}
                onDrop={() => handleDrop(layer)}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: 1,
                  backgroundColor: "#ffffff",
                  borderRadius: 1,
                  boxShadow: 1,
                  cursor: "grab",
                  "&:hover": { backgroundColor: "#eaeaea" },
                }}
              >
                <DragIndicator sx={{ marginRight: 1, color: "#777" }} />
                <Typography sx={{ flexGrow: 1 }}>{layer.name}</Typography>
                <IconButton
                  size="small"
                  onClick={() => toggleVisibility(layer.id)}
                  sx={{ color: layer.visible ? "green" : "gray" }}
                >
                  {layer.visible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                <IconButton
                  size="small"
                  onClick={(event) => handlePopoverOpen(event, layer)}
                  sx={{ color: "#777" }}
                >
                  •••
                </IconButton>
              </Box>
            ))}
          </Stack>

          {/* Popover for layer actions */}
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
          >
            <Box sx={{ padding: 2, width: 200 }}>
              {selectedLayer && (
                <>
                  <Typography variant="subtitle1">
                    {selectedLayer.name}
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: 1,
                      "&:hover": { backgroundColor: "#eaeaea" },
                    }}
                    onClick={() => toggleVisibility(selectedLayer.id)}
                  >
                    {selectedLayer.visible ? (
                      <Visibility sx={{ marginRight: 1 }} />
                    ) : (
                      <VisibilityOff sx={{ marginRight: 1 }} />
                    )}
                    <Typography>
                      {selectedLayer.visible ? "Hide" : "Show"} Layer
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: 1,
                      "&:hover": { backgroundColor: "#eaeaea" },
                    }}
                    onClick={() => removeLayer(selectedLayer.id)}
                  >
                    <Delete sx={{ marginRight: 1, color: "red" }} />
                    <Typography>Delete Layer</Typography>
                  </Box>
                </>
              )}
            </Box>
          </Popover>
        </Box>
      </Popover>
    </Box>
  );
};

export default LayersComponent;
